export enum Role {
    Admin,
    Customer,
    Drop,
    Whole
}

export enum PaymentName {
    Wire,
    Invoice
}


export type RequestCart = {
    id: string;
    count: number;
}
