import {
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  isPlatform,
  useIonLoading,
} from "@ionic/react";
import { Button, ButtonGroup, IconButton, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import CartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import "./ProductModal.css";
import { Product } from "../componentsAxies/TopSeller";
import { CartProps, useGlobalState } from "../GlobalStateProvider";
import { isNumber } from "lodash";
import { OrderUser } from "../pages/Payment";
import { LoadingButton } from "@mui/lab";

type ModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginModal: React.FC<ModalProps> = ({ isOpen, setIsOpen }) => {

  const { setState, state } = useGlobalState();

  const [present, dismiss] = useIonLoading();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);

  const [user, setUser] = useState<OrderUser>({
    City: "",
    CompanyName: "",
    FirstName: "",
    HouseNumber: "",
    LastName: "",
    NIP: "",
    PostCode: "",
    Street: "",
    Email: "",
    Phone: "",
  });

  return (
    <IonModal
      className="product-modal"
      style={{
        "--width": "600px",
        "--height": "auto",
        "--max-height": "100vh",
        "--border-radius": "0px",
        "--overflow": "auto",
        paddingTop: "80px"
        // backdropFilter: "blur(2px)"
      }}
      isOpen={isOpen}
      mode="md"
      onDidDismiss={() => {
        setIsOpen(false);
      }}
    >

      <div className="m-5">
      <div className="row">
        <div className="col-12">
          <h2>Logowanie</h2>
        </div>
      </div>
      <div className="row mt-4">
                    <div className="col-12">
                      <TextField
                        autoComplete="email"
                        label="Email"
                        fullWidth
                        value={user.FirstName}
                        onChange={(e) =>
                          setUser({...user, FirstName: e.target.value})
                        }
                      />
                    </div>
                    
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <TextField
                        autoComplete="password"
                        label="Hasło"
                        fullWidth
                        value={user.FirstName}
                        onChange={(e) =>
                          setUser({...user, FirstName: e.target.value})
                        }
                      />
                    </div>
                    
                  </div>
                  {
                    error
                    ?
<div className="row mt-2">
                    <div className="col-12">
                      <div style={{textAlign: "center", color: "rgb(205, 2, 2)"}}>Wpisane dane są niepoprawne</div>
                    </div>
                  </div>
                  :
                  <></>
                  }
                  
                  <div className="row mt-3">
                    <div className="col-12">
                      <LoadingButton onClick={() => {

                        setIsLoading(true);

                        setTimeout(() => {
                          
                          setError(true);
                          setIsLoading(false);

                        }, 2000);
                      }} loading={isLoading} size="large" fullWidth variant="contained">Zaloguj się</LoadingButton>
                    </div>
                    
                  </div>

      </div>

    </IonModal>
  );
};

export default LoginModal;
