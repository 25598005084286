import {
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  isPlatform,
} from "@ionic/react";
import { Button, ButtonGroup, IconButton, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import CartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import "./ProductModal.css";
import { Product } from "../componentsAxies/TopSeller";
import { CartProps, useGlobalState } from "../GlobalStateProvider";
import { isNumber } from "lodash";

import Lottie from 'react-lottie-player';
import success from './../success.json'


type ModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderSuccessModal: React.FC<ModalProps> = ({ isOpen, setIsOpen }) => {

  const { setState, state } = useGlobalState();


  return (
    <IonModal
      style={{
        "--width": "500px",
        "--height": "auto",
        // "--max-height": "90vh",
        // "--border-radius": "0px",
        // "--overflow": "auto",
        // backdropFilter: "blur(2px)"
      }}
      isOpen={isOpen}
      mode="md"
      onDidDismiss={() => {
        setIsOpen(false);
      }}
    >

      <div className="mt-3 mb-4 px-4">
      <div className="row justify-content-center ">
        <div className="col-auto">
        <Lottie
      loop={false}
      animationData={success}
      play
      style={{ width: 150, height: 150 }}
    />
        </div>
      </div>
      <div className="row ">
        <div className="col-12 text-center">
          <h2>
            Zamówienie zostało złożone
          </h2>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center">
          <Button onClick={() => {


            setIsOpen(false);


          }} size="large" variant="contained">
            Powrót
          </Button>
        </div>
      </div>
      </div>

    </IonModal>
  );
};

export default OrderSuccessModal;
