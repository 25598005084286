import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Input, localePl, Popup, setOptions } from "@mobiscroll/react";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import img1 from '../assets/images/box-item/image-box-26.jpg'
import img2 from '../assets/images/box-item/image-box-27.jpg'
import img3 from '../assets/images/box-item/image-box-28.jpg'
import imga1 from '../assets/images/avatar/avt-11.jpg'
import imga2 from '../assets/images/avatar/avt-12.jpg'
import imga3 from '../assets/images/avatar/avt-13.jpg'

import { Link, useLocation } from "react-router-dom";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper";

const LiveAuction: React.FC = ({}) => {
  const [data] = useState(
    [
        {
            img: "https://products.zadbay.pl/makijaz.png",
            title: "Makijaż",
        },
        {
            img: "https://products.zadbay.pl/makijaz2.png",
            title: "Perfumy",
        },
        {
            img: "https://products.zadbay.pl/pielegnacja.jpg",
            title: "Pielęgnacja",
        },
        // {
        //     img: img1,
        //     title: "Dom",
        // },
        {
            img: "https://products.zadbay.pl/zdrowie.jpg",
            title: "Zdrowie",
        },
        {
            img: "https://products.zadbay.pl/akcesoria.jpg",
            title: "Akcesoria",
        },
        {
            img: "https://products.zadbay.pl/intymne.jpg",
            title: "Intymne",
        },

    ]
)

const [modalShow, setModalShow] = useState(false);

return (
    <>
        <section className="tf-section live-auctions">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading-live-auctions">
                            <h2 className="tf-title pb-20">
                                Kategorie</h2>
                            {/* <Link to="/explore-03" className="exp style2">ZOBACZ WSZYSTKIE</Link> */}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={30}

                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                767: {
                                slidesPerView: 3,
                                },
                                991: {
                                slidesPerView: 5,
                                },
                                
                            }}
                            navigation
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                        >
                                {
                                    data.map((item,index) => (
                                        <SwiperSlide key={index}>
                                            <div className="swiper-container show-shadow carousel auctions">
                                                <div className="swiper-wrapper">
                                                    <div className="swiper-slide">
                                                        <div className="slider-item">										
                                                            <div className="sc-card-product">
                                                                <div className="card-media">
                                                                    <Link to={"/Products?category=" + item.title}><img src={item.img} alt="axies" /></Link>
                                                                    {/* <Link to="/login" className="wishlist-button heart"><span className="number-like">{item.wishlist}</span></Link> */}
                                                                    {/* <div className="featured-countdown">
                                                                        <span className="slogan"></span>
                                                                        <Countdown date={Date.now() + 500000000}>
                                                                            <span>You are good to go!</span>
                                                                        </Countdown>
                                                                    </div> */}
                                                                    {/* <div className="button-place-bid">
                                                                        <button onClick={() => setModalShow(true)} style={{ paddingLeft: 0 }} className="sc-button style-place-bid style fl-button pri-3"><span>Zobacz kartę</span></button>
                                                                        <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3 mt-3"><span>Dodaj do koszyka</span></button>
                                                                    </div> */}
                                                                </div>
                                                                <div className="card-title">
                                                                    <h4><Link to={"/Products?category=" + item.title}>{item.title}</Link></h4>
                                                                    {/* <div className="tags">{item.tags}</div> */}
                                                                </div>
                                                                {/* <div className="meta-info">
                                                                    <div className="author">
                                                                        <div className="avatar">
                                                                            <img src={item.imgAuthor} alt="axies" />
                                                                        </div>
                                                                        <div className="info">
                                                                            <span>Creator</span>
                                                                            <h6> <Link to="/authors-02">{item.nameAuthor}
                                                                            </Link> </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="price">
                                                                        <span>Current Bid</span>
                                                                        <h5> {item.price}</h5>
                                                                    </div>
                                                                </div> */}
                                                            </div>    	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    </>
);
};

export default LiveAuction;
