import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../assets/images/avatar/avt-31.jpg";
import img2 from "../assets/images/avatar/avata_profile.jpg";
import img3 from "../assets/images/avatar/avt-4.jpg";
import img4 from "../assets/images/avatar/avt-3.jpg";
import img5 from "../assets/images/avatar/avt-5.jpg";
import img6 from "../assets/images/avatar/avt-8.jpg";
import img7 from "../assets/images/avatar/avt-6.jpg";
import img8 from "../assets/images/avatar/avt-9.jpg";
import img9 from "../assets/images/avatar/avt-7.jpg";
import img10 from "../assets/images/avatar/avt-10.jpg";
import img11 from "../assets/images/avatar/avt-32.jpg";
import img12 from "../assets/images/avatar/avt-33.jpg";
import {
  IonChip,
  IonIcon,
  IonImg,
  IonLabel,
  IonRippleEffect,
  IonRouterLink,
} from "@ionic/react";

import "./TopSeller.css";
import { cartOutline } from "ionicons/icons";

import {
  Box,
  Button,
  Collapse,
  Fade,
  FormControlLabel,
  Grow,
  IconButton,
  Slide,
  Switch,
} from "@mui/material";

import api from "../services/api"

import Masonry from 'react-masonry-css'

import CartOutline from "@mui/icons-material/ShoppingCartOutlined";
import ProductModal from "../components/ProductModal";

export type ProductFeatures = {
  name: string;
  value: string;
}

export type ProductImage = {
  id: number;
  url: string;
}

export type Product = {
  id: string;
  name: string;
  // image: string;
  price: number;
  brand: string;
  stock: number;
  // vat: number;
  // features: ProductFeatures[];
  ean: string;
  description: string;
  category: string;
  productImages: ProductImage[];
}

export type ProductResponse = {
  size: number;
  products: Product[];
}

const TopSeller: React.FC = ({}) => {
  const [dataTopSellerTab] = useState([
    {
      title: "24 Hours",
    },
    {
      title: "Week",
    },
    {
      title: "Month",
    },
  ]);

  useEffect(() => {

    api.get("/products").then((response) => {

      const data = response.data as ProductResponse;

      setData(data.products);

    })

  }, [])

  const [data, setData] = useState<Product[]>([]);

  const [product, setProduct] = useState<Product>();
  const [isProductOpen, setIsProductOpen] = useState(false);

  return (
    <div>

      <ProductModal product={product} isOpen={isProductOpen} setIsOpen={setIsProductOpen}/>

      <section className="tf-section top-seller home5 bg-style pb-5">
        <div className="container">


              <div className="heading-live-auctions">
                <h2 className="tf-title pb-16">Bestsellery ostatnich 30 dni</h2>
                <Link to="/Products" className="exp style2 see-all">
                  ZOBACZ WSZYSTKO
                </Link>
              </div>
              <div className="flat-tabs seller-tab">
                {/* <Tabs style={{ boxSizing: "border-box" }}> */}
                  {/* <TabList>
                    {dataTopSellerTab.map((item, index) => (
                      <Tab key={index}>{item.title}</Tab>
                    ))}
                  </TabList> */}

                  {/* <div className="content-tab mg-t-24"> */}
                  <Masonry
                  cellSpacing={2}
  breakpointCols={{
    default: 5,
    1100: 4,
    700: 2
  }}
  className="my-masonry-grid"
  columnClassName="my-masonry-grid_column">
  {data.map((item, index) => (
                          <div
                            key={index}
                            className=""
                          >
                            <div onClick={() => {

                              setProduct(item);
                              setIsProductOpen(true)

                            }} >
                            <BoxItem
                              img={item.productImages[0].url}
                              name={item.name}
                              price={item.price}
                              brand={item.brand}
                            />
                            </div>
                          </div>
                    ))}
</Masonry>
                    


              </div>
            </div>
      </section>
    </div>
  );
};

export type BoxItemProps = {
  img: string;
  name: string;
  price: number;
  brand?: string;
};

export const BoxItem: React.FC<BoxItemProps> = ({ img, name, price, brand }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    // <div className="box-item">
    //   <div
    //     className="sc-author-box style-3"
    //     onMouseEnter={() => setIsOpen(true)}
    //     onMouseLeave={() => setIsOpen(false)}
    //   >
    //     <IonRouterLink routerLink="#">
    //       <div className="author-style2 flex">
    //         <div className="author-avatar">
    //           <IonImg src={img} alt="Axies" className="avatar" />
    //           {/* <div className="badge">
    //                                       <i className="ripple"></i>
    //                                     </div> */}
    //         </div>
    //         <div className="author-infor">
    //           <h5>{name}</h5>
    //           <div className="tag">{brand}</div>
    //           <div
    //             style={{ margin: 0 }}
    //             className="row justify-content-between"
    //           >
    //             <div className="col-auto" style={{ position: "relative", minHeight: "31px" }}>
    //               <span className="price" style={{
    //                 position: "absolute",
    //                 height: "21px",
    //                 width: "100px",
    //                 bottom: "10px"
    //               }}>{price}</span>
    //             </div>

    //             <Box sx={{ display: "flex" }}>
    //               <Fade style={{ transitionDuration: "200ms", marginTop: "-7px" }} in={isOpen}>
    //                 <div className="col-auto">
    //                   <span className="price" style={{ float: "right" }}>
    //                     <IonChip outline color="primary">
    //                       <IonLabel>Do koszyka</IonLabel>
    //                       <IonIcon icon={cartOutline} />
    //                     </IonChip>
    //                   </span>
    //                 </div>
    //               </Fade>
    //             </Box>
    //           </div>
    //         </div>
    //       </div>
    //     </IonRouterLink>
    //   </div>
    // </div>

    <div  >
      <div
        className={"sc-card-product"}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <div className="card-media">
          <img src={img} alt="axies" />
        </div>
        <div className="card-title2">
          {/* <div className="sd-fader">
            
            </div> */}

          <h5 className="mb-1" style={{
            fontSize: "16px"
          }} >{name}</h5>
          {/* <div className="tags">{item.tags}</div> */}
          <div className="info">
            <span>{brand}</span>
          </div>
        </div>
        <div className="meta-info" style={{ display: "block", position: "relative" }}>
          {/* <div className="author">
            <div className="info">
              <Box sx={{ display: "flex" }}>
                <Fade
                  style={{ transitionDuration: "200ms", marginTop: "-7px" }}
                  in={isOpen}
                >
                  <div className="col-auto">
                    <span className="price" style={{ float: "right" }}>
                      <Button
                        style={{ height: "45px" }}
                        variant="outlined"
                        startIcon={<CartOutline color="primary" />}
                      >
                        dodaj do koszyka
                      </Button>
                    </span>
                  </div>
                </Fade>
              </Box>
            </div>
          </div> */}
          <div className="price">
            <h5
              style={{
                fontSize: "19px",
                margin: "0",
                color: "var(--primary-color3)",
                textAlign: "center",
              }}
            >
              {
                new Intl.NumberFormat("pl-PL", {
                  style: "currency",
                  currency: "PLN",
                }).format(price)
              }
            </h5>
          </div>

          <Box sx={{ display: "flex", position: "absolute", top: "-20px", right: "3px" }}>
            <Fade
              style={{ transitionDuration: "200ms", marginTop: "-7px" }}
              in={isOpen}
            >
              <div className="col-auto">
                <span className="price">
                  {/* <Button
                        style={{ height: "45px" }}
                        variant="outlined"
                        startIcon={<CartOutline color="primary" />}
                      >
                      </Button> */}
                  {/* <IconButton color="success" aria-label="Dodaj do koszyka" style={{ border: "1px solid rgb(0 128 0)" }}>
                    <CartOutline style={{ fontSize: "31px" }} />
                  </IconButton> */}
                </span>
              </div>
            </Fade>
          </Box>
        </div>
        {/* <IonRippleEffect/> */}
      </div>
    </div>
  );
};

export default TopSeller;
