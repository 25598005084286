import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import "./Home.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { useHistory } from "react-router";

import { ForwardProps } from "../components/Home/CategorySearch";


import { Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import mainImage from "./../img/illustrations/illustration-5.svg";
import MobiSelect from "../components/Mobi/MobiSelect";
import { cartOutline, personCircle } from "ionicons/icons";
import MobiInput from "../components/Mobi/MobiInput";

import Chemistry from "../svg/chemia.svg";
import Biology from "../svg/biologia.svg";

import CourseJson from "../lottie/course.json";
import Image from "../components/Ebert/Image";

import HeaderStyle2 from "../componentsAxies/header/HeaderStyle2";
import Footer from "../componentsAxies/footer/Footer";
import SliderStyle2 from "../componentsAxies/SliderStyle2";
// import heroSliderData from '../assets/fake-data/data-slider';
// import BrowCategory from '../componentsAxies/layouts/home-5/BrowCategory';
import LiveAuction from "../componentsAxies/LiveAuction";
import TopSeller from "../componentsAxies/TopSeller"
import { ButtonGroup, Button } from "@mui/material";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import ProductModal from "../components/ProductModal";
// import TodayPicks from '../componentsAxies/layouts/home-5/TodayPicks';
// import todayPickData from '../assets/fake-data/data-today-pick';
// import PopularCollection from '../componentsAxies/layouts/home-5/PopularCollection';
// import Create from '../componentsAxies/layouts/home-2/Create';

import {Helmet} from "react-helmet";

const Home: React.FC = () => {

  const [ isDropshipping, setIsDropshipping ] = useState(true);

  return (
    <IonPage className="home-7">
      {/* <CustomHeaderFade
        page={"Strona główna"}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
      /> */}

      <Helmet>
        <title>Zadbay.pl - hurtownia dropshipping</title>
        <meta name="description" content="" />
      </Helmet>

      <IonContent
        id="home-page-controller"
        fullscreen={true}
        className={"background-lightgrey slide-transition-leave"}
        scrollEvents={false}
      >
        <HeaderStyle2 />
        <SliderStyle2 />
        {/* <BrowCategory /> */}

        <LiveAuction />

        <TopSeller />
        {/* <TodayPicks data={todayPickData} />
          <PopularCollection />
          <Create /> */}
        {/* <section className="tf-section drop-hurt" style={{ background: "white" }}>
          <div className="themesflat-container">
            <div className="py-4 px-5">
              <div className="row justify-content-center">
                <ButtonGroup
                  className="category-choose"
                  variant="outlined"
                  size="large"
                  aria-label="outlined button group"
                >
                  <Button variant={isDropshipping ? "contained" : "outlined"} onClick={() => setIsDropshipping(true)} >Dropshipping</Button>
                  <Button variant={isDropshipping ? "outlined" : "contained"} onClick={() => setIsDropshipping(false)} >Oferta hurtowa</Button>
                </ButtonGroup>
              </div>

              {
                isDropshipping
                ?
                <div className="row" style={{ marginTop: "70px" }}>
                <div className="col-6">
                  <div className="row justify-content-center">
                    <div className="col-12 text-center">
                      <h2>Oferta dla dropshippingu</h2>
                      <hr
                        className="primary"
                        style={{
                          margin: "auto",
                          marginTop: "10px",
                          width: "135px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center" style={{ marginTop: "60px" }}>
                    <div className="col-12 text-center">
                      <div className="desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores voluptate et aliquid ab nihil temporibus facere magnam, aut debitis laborum eum eius? Ea accusantium optio quidem nobis alias in molestiae!</div>
                    </div>
                  </div>
                  <div className="row justify-content-center" style={{ marginTop: "20px" }}>
                    <div className="col-12 text-center">
                      <div className="desc">Sposób realizowania zamówień:</div>
                    </div>
                  </div>
                  <Timeline>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot >
            
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Eat</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Code</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent>Sleep</TimelineContent>
      </TimelineItem>
    </Timeline>
                </div>
                <div className="col-6">asd</div>
              </div>
              :
              <></>
              }

            </div>
          </div>
        </section> */}

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
