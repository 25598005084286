import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonRadioGroup,
  IonRadio,
  useIonLoading,
} from "@ionic/react";
// import "./Payment.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { useHistory } from "react-router";

import { ForwardProps } from "../components/Home/CategorySearch";

import { Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import mainImage from "./../img/illustrations/illustration-5.svg";
import MobiSelect from "../components/Mobi/MobiSelect";
import { cart, cartOutline, personCircle } from "ionicons/icons";
import MobiInput from "../components/Mobi/MobiInput";

import Chemistry from "../svg/chemia.svg";
import Biology from "../svg/biologia.svg";

import CourseJson from "../lottie/course.json";
import Image from "../components/Ebert/Image";

import HeaderStyle2 from "../componentsAxies/header/HeaderStyle2";
import Footer from "../componentsAxies/footer/Footer";
import SliderStyle2 from "../componentsAxies/SliderStyle2";
// import heroSliderData from '../assets/fake-data/data-slider';
// import BrowCategory from '../componentsAxies/layouts/home-5/BrowCategory';
import LiveAuction from "../componentsAxies/LiveAuction";
import TopSeller, { BoxItem, Product } from "../componentsAxies/TopSeller";
import {
  ButtonGroup,
  Button,
  TextField,
  Paper,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import ProductModal from "../components/ProductModal";
// import TodayPicks from '../componentsAxies/layouts/home-5/TodayPicks';
// import todayPickData from '../assets/fake-data/data-today-pick';
// import PopularCollection from '../componentsAxies/layouts/home-5/PopularCollection';
// import Create from '../componentsAxies/layouts/home-2/Create';

import api from "../services/api";

import Masonry from "react-masonry-css";
import { CartProps, useGlobalState } from "../GlobalStateProvider";

import { PaymentName, RequestCart, Role } from "../services/Utility";
import OrderSuccessModal from "../components/OrderSuccessModal";

import { Helmet } from "react-helmet";

type PaymentMethod = {
  name: string;
  text: string;
  access: Role[];
  type: PaymentName;
};

type OrderProduct = {
  Id: string;
  Count: number;
};

export type OrderUser = {
  FirstName: string;
  LastName: string;
  NIP: string;
  CompanyName: string;
  PostCode: string;
  City: string;
  Street: string;
  HouseNumber: string;
  Email: string;
  Phone: string;
};

type Order = {
  ShippingUser: OrderUser;
  PaymentUser: OrderUser;
  Products: OrderProduct[];
  Label: string;
  Payment: undefined | "Prepaid";
};

const paymentMethods: PaymentMethod[] = [
  {
    name: "Przelew tradycyjny",
    text: "Po zamówieniu wykonaj przedpłatę na nasze konto bankowe",
    access: [Role.Admin, Role.Customer, Role.Drop, Role.Whole],
    type: PaymentName.Wire,
  },
  // {
  //   name: "Faktura",
  //   text: "Dokonaj płatności na koniec okresu rozliczenia. Dostęp tylko dla zweryfikowanych firm",
  //   access: [Role.Admin, Role.Drop, Role.Whole],
  //   type: PaymentName.Invoice,
  // },
];

const Payment: React.FC = () => {
  const { setState, state } = useGlobalState();

  const [product, setProduct] = useState<Product>();
  const [isProductOpen, setIsProductOpen] = useState(false);

 

  const [data, setData] = useState<Product[]>([]);

  const [labelFile, setLabelFile] = useState<File>();

  const [selected, setSelected] = useState<PaymentName>(0);

  useEffect(() => {
    api
      .get("/products", {
        params: {
          size: 18,
        },
      })
      .then((response) => {
        const data = response.data as Product[];

        setData(data);
      });
  }, []);

  const [order, setOrder] = useState<Order>({
    PaymentUser: {
      City: "",
      CompanyName: "",
      FirstName: "",
      HouseNumber: "",
      LastName: "",
      NIP: "",
      PostCode: "",
      Street: "",
      Email: "",
      Phone: "",
    },
    ShippingUser: {
      City: "",
      CompanyName: "",
      FirstName: "",
      HouseNumber: "",
      LastName: "",
      NIP: "",
      PostCode: "",
      Street: "",
      Email: "",
      Phone: "",
    },
    Products: [],
    Label: "",
    Payment: undefined,
  });

  const [present, dismiss] = useIonLoading();

  const [ischecked, setIsChecked] = useState(false);

  const [isOrderSuccessOpen, setIsOrderSuccessOpen] = useState(false);

  const isButtonDisabled = () => {

    if(!ischecked)
    {
      return true;
    }

    if(!order.PaymentUser.Email)
    {
      return true;
    }

    if(!order.PaymentUser.Phone)
    {
      return true;
    }

    if(!labelFile)
    {
      return true;
    }

    if(!state.cart)
    {
      return true;
    }

    if(state.cart.length <= 0)
    {
      return true;
    }

    if(
      !(order.PaymentUser.FirstName && order.PaymentUser.LastName) && 
      !(order.PaymentUser.CompanyName && order.PaymentUser.NIP))
    {
      return true;
    }

    return false;

  }

  return (
    <IonPage className="home-7">
      {/* <CustomHeaderFade
        page={"Strona główna"}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
      /> */}

      <Helmet>
        <title>Płatność</title>
        <meta name="description" content="" />
      </Helmet>

      <OrderSuccessModal
        isOpen={isOrderSuccessOpen}
        setIsOpen={setIsOrderSuccessOpen}
      />

      <IonContent
        id="payment-page-controller"
        fullscreen={true}
        className={"background-lightgrey slide-transition-leave"}
        scrollEvents={false}
      >
        <HeaderStyle2 />

        <ProductModal
          product={product}
          isOpen={isProductOpen}
          setIsOpen={setIsProductOpen}
        />

        <div
          className="container"
          style={{
            marginTop: "120px",
            marginBottom: "175px",
          }}
        >
          <div className="row justify-content-between">
            <div className="col-7">
              <h3>Dane płatnika</h3>

              <div className="row mt-3">
                <div className="col-6">
                  <TextField
                    autoComplete="given-name"
                    label="Imię"
                    fullWidth
                    value={order.PaymentUser.FirstName}
                    onChange={(e) =>
                      setOrder((prev) => ({
                        ...prev,
                        PaymentUser: {
                          ...prev.PaymentUser,
                          FirstName: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="col-6">
                  <TextField
                    autoComplete="family-name"
                    label="Nazwisko"
                    fullWidth
                    value={order.PaymentUser.LastName}
                    onChange={(e) =>
                      setOrder((prev) => ({
                        ...prev,
                        PaymentUser: {
                          ...prev.PaymentUser,
                          LastName: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <TextField
                    autoComplete="organization"
                    label="Nazwa firmy"
                    fullWidth
                    value={order.PaymentUser.CompanyName}
                    onChange={(e) =>
                      setOrder((prev) => ({
                        ...prev,
                        PaymentUser: {
                          ...prev.PaymentUser,
                          CompanyName: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="col-6">
                  <TextField
                    autoComplete="nip"
                    label="NIP"
                    fullWidth
                    value={order.PaymentUser.NIP}
                    onChange={(e) =>
                      setOrder((prev) => ({
                        ...prev,
                        PaymentUser: {
                          ...prev.PaymentUser,
                          NIP: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <TextField
                    autoComplete="email"
                    label="Email"
                    fullWidth
                    value={order.PaymentUser.Email}
                    onChange={(e) =>
                      setOrder((prev) => ({
                        ...prev,
                        PaymentUser: {
                          ...prev.PaymentUser,
                          Email: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="col-6">
                  <TextField
                    autoComplete="tel"
                    label="Numer telefonu"
                    fullWidth
                    value={order.PaymentUser.Phone}
                    onChange={(e) =>
                      setOrder((prev) => ({
                        ...prev,
                        PaymentUser: {
                          ...prev.PaymentUser,
                          Phone: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>

              <h3 className="mt-5">
                Dane do wysyłki{" "}
                <span style={{ color: "rgb(143, 143, 143)" }}>
                  (osoba odbierająca paczkę)
                </span>
              </h3>

              <div className="row mt-3">
                <div className="col-6">
                  <TextField
                    autoComplete="fname"
                    label="Imię"
                    fullWidth
                    value={order.ShippingUser.FirstName}
                    onChange={(e) =>
                      setOrder((prev) => ({
                        ...prev,
                        ShippingUser: {
                          ...prev.ShippingUser,
                          FirstName: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="col-6">
                  <TextField
                    autoComplete="lname"
                    label="Nazwisko"
                    fullWidth
                    value={order.ShippingUser.LastName}
                    onChange={(e) =>
                      setOrder((prev) => ({
                        ...prev,
                        ShippingUser: {
                          ...prev.ShippingUser,
                          LastName: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>

              {/* <div className="row mt-3">
  <div className="col-6">
    <TextField autoComplete="fname" label="Imię" fullWidth value={order.ShippingUser.FirstName}
      onChange={(e) =>
        setOrder((prev) => ({
          ...prev,
          PaymentUser: {
            ...prev.PaymentUser,
            FirstName: e.target.value,
          },
        }))} />
  </div>
  <div className="col-6">
    <TextField autoComplete="lname" label="Nazwisko" fullWidth value={order.ShippingUser.LastName}
      onChange={(e) =>
        setOrder((prev) => ({
          ...prev,
          PaymentUser: {
            ...prev.PaymentUser,
            LastName: e.target.value,
          },
        }))} />
  </div>
</div> */}

              <h3 className="mt-5 mb-3">Etykiety</h3>

              {/* <ButtonGroup variant="contained" aria-label="outlined primary button group">
  <Button>One</Button>
  <Button>Two</Button>
</ButtonGroup> */}

              <form action="#">
                <label className="uploadFile">
                  <span className="filename">
                    {labelFile ? (
                      <div style={{ display: "inline-flex" }}>
                        Dodano etykietę:&nbsp;
                        <div style={{ fontWeight: 700 }}>
                          {labelFile.name}&nbsp;
                        </div>{" "}
                        | {labelFile.type}
                      </div>
                    ) : (
                      "PDF, JPG lub PNG. Maksymalna wielkość pliku: 5mb."
                    )}
                  </span>
                  <input
                    type="file"
                    className="inputfile form-control"
                    name="file"
                    onChange={(e) => {
                      const files = e.target.files;

                      if (files) {
                        setLabelFile(files[0]);
                      }
                    }}
                  />
                </label>
              </form>
            </div>

            <div className="col-4">
              <Paper
                elevation={2}
                style={{
                  padding: "15px 0",
                }}
              >
                <div style={{ paddingBottom: "10px" }}>
                  <h3 style={{ marginBottom: "7px", textAlign: "center" }}>
                    Koszyk
                  </h3>

                  <IonList style={{ maxHeight: "50vh", overflow: "auto" }}>
                    {state.cart?.map((e) => {
                      return (
                        <IonItem
                          className="cart-item"
                          style={{
                            "--padding-start": "10px",
                            "--padding-end": "10px",
                            opacity: 0,
                            animation:
                              "ease 0.6s 1 normal forwards running slide-up",
                          }}
                          lines="none"
                        >
                          {/* <IconButton
                        color="error"
                        aria-label="remove cart"
                        component="span"
                        className="cart-remove"
                        onClick={() => {
                          const tempCart = state.cart;

                          if (tempCart) {
                            let newCart: CartProps[] = [];
                            for (const a of tempCart) {
                              if (a.product.id != e.product.id) {
                                newCart.push({ ...a });
                              }
                            }

                            setState((prev) => ({
                              ...prev,
                              ...{
                                cart: newCart,
                              },
                            }));
                          }
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton> */}
                          <div>
                            <img
                              className="cart-img"
                              src={
                                e.product.productImages.length > 0
                                  ? e.product.productImages[0].url
                                  : ""
                              }
                              alt="product"
                            />
                          </div>
                          <IonLabel style={{ margin: "auto 0" }}>
                            <IonItem className="desc-header" lines="none">
                              <IonLabel
                                style={{
                                  textOverflow: "inherit",
                                  whiteSpace: "initial",
                                  lineHeight: "19px",
                                }}
                              >
                                {e.product.name}
                              </IonLabel>
                            </IonItem>
                            <IonItem lines="none">
                              <IonLabel>
                                <span>{e.product.brand}</span>
                                <span style={{ float: "right" }}>
                                  {e.count} &times;{" "}
                                  {new Intl.NumberFormat("pl-PL", {
                                    style: "currency",
                                    currency: "PLN",
                                  }).format(e.product.price)}
                                </span>
                              </IonLabel>
                            </IonItem>
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                  </IonList>
                </div>
                <hr style={{ margin: "0 0 10px 0" }} />
                <div>
                  <div
                    style={{ margin: "0", padding: "5px 20px" }}
                    className="row justify-content-between"
                  >
                    <div className="col-auto">
                      <strong>Razem</strong>
                    </div>
                    <div className="col-auto">
                      {new Intl.NumberFormat("pl-PL", {
                        style: "currency",
                        currency: "PLN",
                      }).format(
                        state.cart
                          ? state.cart.reduce(
                              (previousValue, currentValue) =>
                                previousValue +
                                currentValue.product.price * currentValue.count,
                              0
                            )
                          : 0
                      )}
                    </div>
                  </div>
                </div>
              </Paper>

              <div className="row mt-5">
                <div className="col-12">
                  <h3 className="mb-4">Sposób płatności</h3>

                  <IonRadioGroup
                    value={selected}
                    onIonChange={(e) => {
                      setSelected(e.detail.value);
                    }}
                    className="radio-primary"
                  >
                    {paymentMethods.map((e) => {
                      return (
                        <IonItem
                          lines="none"
                          className={
                            selected == e.type ? "shadow-primary active" : ""
                          }
                          style={{
                            borderRadius: "14px",
                          }}
                        >
                          <div className="mb-2 mt-2">
                            <div className="d-flex align-items-center ">
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: "20px",
                                }}
                                className="ms-0 mb-1"
                              >
                                {e.name}
                              </span>
                            </div>
                            <div className="d-flex align-items-center ">
                              <span
                                className="ms-0"
                                style={{
                                  lineHeight: "19px",
                                }}
                              >
                                {e.text}
                              </span>
                            </div>
                            {/* <div className="d-flex align-items-center mb-0">
                          <ins className="h2 mb-0 ms-2">49,99 zł</ins>
                        </div> */}
                          </div>
                          <IonRadio slot="end" value={e.type} />
                        </IonItem>
                      );
                    })}
                  </IonRadioGroup>
                </div>
              </div>

              <div className="row justify-content-center mt-3">
                <div className="col-auto">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => setIsChecked(e.target.checked)}
                        />
                      }
                      label={
                        <>
                          <strong style={{ color: "red", marginRight: "3px" }}>
                            *
                          </strong>
                          <span>
                            Zagdzam się z{" "}
                            <a
                              style={{
                                fontWeight: 500,
                                color: "rgb(21, 101, 192)",
                              }}
                              href="/regulamin.pdf"
                              target="_blank"
                            >
                              regulaminem
                            </a>{" "}
                            sklepu
                          </span>
                        </>
                      }
                    />
                  </FormGroup>
                </div>
                <div className="col-auto mt-3">
                  <Button
                    disabled={isButtonDisabled()}
                    style={{
                      width: "280px",
                      fontSize: "19px",
                    }}
                    size="large"
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      let cart = state.cart;
                      let requestCart: RequestCart[] = [];

                      if (cart) {
                        for (const n of cart) {
                          requestCart.push({
                            id: n.product.id,
                            count: n.count,
                          });
                        }
                      }

                      present({
                        message: "Dokonywanie zamówienia...",
                        duration: 6000,
                      });

                      if (labelFile) {
                        let formData = new FormData();

                        if (labelFile) {
                          formData.append("label", labelFile);
                        }

                        api
                          .post("/order/label", formData)
                          .then((responseLabel) => {
                            api
                              .post("/order/dropshipping", {
                                ...order,
                                Label: responseLabel.data,
                                Cart: requestCart,
                              })
                              .then((response) => {
                                dismiss();

                                setOrder({
                                  PaymentUser: {
                                    City: "",
                                    CompanyName: "",
                                    FirstName: "",
                                    HouseNumber: "",
                                    LastName: "",
                                    NIP: "",
                                    PostCode: "",
                                    Street: "",
                                    Email: "",
                                    Phone: "",
                                  },
                                  ShippingUser: {
                                    City: "",
                                    CompanyName: "",
                                    FirstName: "",
                                    HouseNumber: "",
                                    LastName: "",
                                    NIP: "",
                                    PostCode: "",
                                    Street: "",
                                    Email: "",
                                    Phone: "",
                                  },
                                  Products: [],
                                  Label: "",
                                  Payment: undefined,
                                });

                                setLabelFile(undefined);

                                setState((prev) => ({
                                  ...prev,
                                  ...{
                                    cart: [],
                                  },
                                }));

                                setIsOrderSuccessOpen(true);
                              });
                          });
                      } else {
                        api
                          .post("/order/dropshipping", {
                            ...order,
                            Label: "",
                            Cart: requestCart,
                          })
                          .then((response) => {
                            dismiss();

                            setOrder({
                              PaymentUser: {
                                City: "",
                                CompanyName: "",
                                FirstName: "",
                                HouseNumber: "",
                                LastName: "",
                                NIP: "",
                                PostCode: "",
                                Street: "",
                                Email: "",
                                Phone: "",
                              },
                              ShippingUser: {
                                City: "",
                                CompanyName: "",
                                FirstName: "",
                                HouseNumber: "",
                                LastName: "",
                                NIP: "",
                                PostCode: "",
                                Street: "",
                                Email: "",
                                Phone: "",
                              },
                              Products: [],
                              Label: "",
                              Payment: undefined,
                            });

                            setLabelFile(undefined);

                            setState((prev) => ({
                              ...prev,
                              ...{
                                cart: [],
                              },
                            }));

                            setIsOrderSuccessOpen(true);
                          });
                      }
                    }}
                  >
                    Zamawiam z obowiązkiem zapłaty
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Payment;
