import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonLoading,
} from "@ionic/react";
import "./Products.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { useHistory, useLocation } from "react-router";

import mainImage from "./../img/illustrations/illustration-5.svg";
import MobiSelect from "../components/Mobi/MobiSelect";
import { cartOutline, personCircle } from "ionicons/icons";
import MobiInput from "../components/Mobi/MobiInput";

import Chemistry from "../svg/chemia.svg";
import Biology from "../svg/biologia.svg";

import CourseJson from "../lottie/course.json";
import Image from "../components/Ebert/Image";

import HeaderStyle2 from "../componentsAxies/header/HeaderStyle2";
import Footer from "../componentsAxies/footer/Footer";
import SliderStyle2 from "../componentsAxies/SliderStyle2";
// import heroSliderData from '../assets/fake-data/data-slider';
// import BrowCategory from '../componentsAxies/layouts/home-5/BrowCategory';
import LiveAuction from "../componentsAxies/LiveAuction";
import TopSeller, {
  BoxItem,
  Product,
  ProductResponse,
} from "../componentsAxies/TopSeller";
import {
  ButtonGroup,
  Button,
  TextField,
  Pagination,
  Stack,
  Breadcrumbs,
  Link,
  Typography,
  Grid,
} from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import ProductModal from "../components/ProductModal";
// import TodayPicks from '../componentsAxies/layouts/home-5/TodayPicks';
// import todayPickData from '../assets/fake-data/data-today-pick';
// import PopularCollection from '../componentsAxies/layouts/home-5/PopularCollection';
// import Create from '../componentsAxies/layouts/home-2/Create';

import api from "../services/api";

import Masonry from "react-masonry-css";

import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { Helmet } from "react-helmet";

const GenerateBreadcrumbs = (names: string[]) => {
  names.unshift("Wszystko");

  let _breadcrumbs: JSX.Element[] = [];

  if (names.length > 0) {
    for (let i = 0; i < names.length - 1; i++) {
      _breadcrumbs.push(
        <Link underline="hover" key="1" color="inherit">
          {names[i]}
        </Link>
      );
    }
  }

  _breadcrumbs.push(
    <Typography key="3" color="text.primary">
      {names[names.length - 1]}
    </Typography>
  );

  return _breadcrumbs;
};

type RenderTree = {
  id: string;
  name: string;
  children?: readonly RenderTree[];
};

// const categories: RenderTree = {
//   id: 'root',
//   name: 'Wszystko',
//   children: [
//     {
//       id: '1',
//       name: 'Perfumy',
//       children: [
//         {
//           id: '7',
//           name: 'Linia kąpielowa',
//         },
//         {
//           id: '18',
//           name: 'Zapachy',
//         }
//       ]
//     },
//     {
//       id: '2',
//       name: 'Makijaż',
//       children: [
//         {
//           id: '9',
//           name: 'Makijaż - Bazy',
//           children: [
//             {
//               id: '10',
//               name: 'Twarz',
//             }
//           ]
//         },
//         {
//           id: '11',
//           name: 'Makijaż - Akcesoria',
//           children: [
//             {
//               id: '13',
//               name: 'Kosmetyczki',
//             },
//             {
//               id: '14',
//               name: 'Pędzle',
//             }
//           ]
//         },
//         {
//           id: '12',
//           name: 'Makijaż - Oczy',
//           children: [
//             {
//               id: '15',
//               name: 'Makijaż brwi',
//             }
//           ]
//         }
//       ]
//     },
//     {
//       id: '3',
//       name: 'Pielęgnacja',
//       children: [
//         {
//           id: '8',
//           name: 'Pielęgnacja słoneczna',
//         },
//         {
//           id: '16',
//           name: 'Pielęgnacja twarzy',
//           children: [
//             {
//               id: '17',
//               name: 'Twarz',
//             },
//           ]
//         },
//         {
//           id: '19',
//           name: 'Włosy',
//           children: [
//             {
//               id: '20',
//               name: 'Maski do włosów',
//             },
//             {
//               id: '21',
//               name: 'Szampony',
//             },
//           ]
//         },
//         {
//           id: '22',
//           name: 'Pielęgnacja ciała',
//         },
//         {
//           id: '25',
//           name: 'Akcesoria',
//         },
//       ]
//     },
//     {
//       id: '4',
//       name: 'Zdrowie',
//     },
//     {
//       id: '5',
//       name: 'Dom',
//       children: [
//         {
//           id: '23',
//           name: 'Środki czystości',
//           children: [
//             {
//               id: '24',
//               name: 'Dywany i tapicerka',
//             },
//           ]
//         },
//       ]
//     },
//     {
//       id: '6',
//       name: 'Intymne',
//     },
//     {
//       id: '26',
//       name: 'Akcesoria',
//     },
//   ],
// };

const categories: RenderTree = {
  id: "root",
  name: "Wszystko",
  children: [
    {
      id: "3",
      name: "Kosmetyki i perfumy",
    },
    {
      id: "4",
      name: "Przemysł",
    },
    {
      id: "6",
      name: "Elektronika",
    },
    {
      id: "1",
      name: "Artykuły +18",
    },
  ],
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Products: React.FC = () => {
  let query = useQuery();

  const [present, dismiss] = useIonLoading();

  const history = useHistory();

  const [product, setProduct] = useState<Product>();
  const [isProductOpen, setIsProductOpen] = useState(false);

  const [data, setData] = useState<Product[]>([]);

  const [productSearch, setProductSearch] = useState("");

  const [productSearchInput, setProductSearchInput] = useState("");

  const [onlyOnce, setOnlyOnce] = useState(true);

  const [choosedCategory, setChoosedCategory] = useState("");

  const [size, setSize] = useState(1);
  const [page, setPage] = useState(1);

  const renderTree = (nodes: RenderTree, fullName: string, id: string) => (
    <TreeItem
      onClick={() => {
        setPage(1);
        setChoosedCategory(id);
        // history.push("/Products?category=" + encodeURIComponent(id));
        SearchProduct(productSearch, id);
      }}
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      sx={{
        paddingTop: "3px",
        paddingBottom: "3px",
      }}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) =>
            renderTree(
              node,
              fullName ? fullName + " > " + node.name : node.name,
              node.id
            )
          )
        : null}
    </TreeItem>
  );

  // useEffect(() => {}, [page]);

  useEffect(() => {
    

    const querySearch = query.get("search");
    const queryCategory = query.get("category");

    if (querySearch) {
      setProductSearch(querySearch);
      setProductSearchInput(querySearch);
    }

    if (queryCategory) {
      setChoosedCategory(queryCategory);
    }

    SearchProduct(querySearch, queryCategory);
  }, [page]);

  const SearchProduct = async (
    text: string | null,
    choosedCategory: string | null
  ) => {
   

    setData([]);
    
    await present("Wyszukiwanie produktów...", 3000);

    api
      .get("/products", {
        params: {
          text: text ? text : "",
          category: choosedCategory ? choosedCategory : "",
          size: 30,
          page: page,
        },
      })
      .then(async (response) => {
        const data = response.data as ProductResponse;

        setSize(data.size);
        setData(data.products);

        await dismiss();

        document.querySelector('#product-autocomplete')?.scrollIntoView()
      });
  };

  return (
    <IonPage className="home-7">
      {/* <CustomHeaderFade
        page={"Strona główna"}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
      /> */}

      <Helmet>
        <title>Produkty</title>
        <meta name="description" content="" />
      </Helmet>

      <IonContent
        id="products-page-controller"
        fullscreen={true}
        className={"background-lightgrey slide-transition-leave"}
        scrollEvents={false}
      >
        <HeaderStyle2 />

        <ProductModal
          product={product}
          isOpen={isProductOpen}
          setIsOpen={setIsProductOpen}
        />

        <div
          className="container "
          style={{
            marginTop: "120px",
            marginBottom: "180px",
          }}
        >
          <div className="row">
            <div className="col-12">
              <TextField
                value={productSearchInput}
                onChange={(e) => {
                  setProductSearchInput(e.target.value);
                }}
                onKeyPress={(e) => {
                  const target = e.target as HTMLInputElement;

                  if (target) {
                    if (e.key === "Enter") {
                      // if (target.value) {
                      //   history.push("/Products?search=" + target.value);
                      // } else {
                      //   history.push("/Products");
                      // }
                      SearchProduct(target.value, choosedCategory);
                    }
                  }
                }}
                inputMode="search"
                autoComplete="24y24t342rcf31t"
                label="Wyszukaj produkt (nazwa lub EAN)"
                id="product-autocomplete"
                fullWidth
                // value={productSearch}
                // onChange={(e) =>
                //   setProductSearch(e.target.value)
                // }
              />
            </div>
          </div>

          {/* <div className="row justify-content-end">
<div className="col-auto">
<Pagination className="mt-4 mb-4" style={{
  marginLeft: "auto"
}} count={10} />
</div>
</div> */}

          <div className="row mt-4">
            <div className="col-3">

              <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={["root"]}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ flexGrow: 1, maxWidth: 400, overflowY: "hidden" }}
              >
                {renderTree(categories, "", "")}
              </TreeView>
            </div>

            <div className="col-9">
              <Stack spacing={2} className="mb-4">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {GenerateBreadcrumbs(choosedCategory.split(" > "))}
                </Breadcrumbs>
              </Stack>

              <Pagination
                color="primary"
                count={Math.ceil(size / 30)}
                page={page}
                onChange={(e, d) => setPage(d)}
              />

              <Masonry
                cellSpacing={2}
                breakpointCols={{
                  default: 4,
                  1100: 3,
                  700: 2,
                }}
                // breakpointCols={{
                //   default: 3,
                //   1100: 2,
                //   700: 2,
                // }}
                className="my-masonry-grid mt-1"
                columnClassName="my-masonry-grid_column"
              >
                {data.map((item, index) => (
                  <div key={index} className="">
                    <div
                      onClick={() => {
                        setProduct(item);
                        setIsProductOpen(true);
                      }}
                    >
                      <BoxItem
                        img={
                          item.productImages.length > 0
                            ? item.productImages[0].url
                            : ""
                        }
                        name={item.name}
                        price={item.price}
                        brand={item.brand}
                      />
                    </div>
                  </div>
                ))}
              </Masonry>

              <Pagination
                color="primary"
                count={Math.ceil(size / 30)}
                page={page}
                onChange={(e, d) => setPage(d)}
              />

            </div>
          </div>
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Products;
