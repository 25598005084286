import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonLoading,
  IonRadioGroup,
  IonRadio,
} from "@ionic/react";
import "./Profile.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { useHistory, useLocation } from "react-router";


import mainImage from "./../img/illustrations/illustration-5.svg";
import MobiSelect from "../components/Mobi/MobiSelect";
import { cartOutline, personCircle } from "ionicons/icons";
import MobiInput from "../components/Mobi/MobiInput";

import Chemistry from "../svg/chemia.svg";
import Biology from "../svg/biologia.svg";

import CourseJson from "../lottie/course.json";
import Image from "../components/Ebert/Image";

import HeaderStyle2 from "../componentsAxies/header/HeaderStyle2";
import Footer from "../componentsAxies/footer/Footer";
import SliderStyle2 from "../componentsAxies/SliderStyle2";
// import heroSliderData from '../assets/fake-data/data-slider';
// import BrowCategory from '../componentsAxies/layouts/home-5/BrowCategory';
import LiveAuction from "../componentsAxies/LiveAuction";
import TopSeller, { BoxItem, Product } from "../componentsAxies/TopSeller";
import { ButtonGroup, Button, TextField, Pagination, Stack, Breadcrumbs, Link, Typography } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import ProductModal from "../components/ProductModal";
// import TodayPicks from '../componentsAxies/layouts/home-5/TodayPicks';
// import todayPickData from '../assets/fake-data/data-today-pick';
// import PopularCollection from '../componentsAxies/layouts/home-5/PopularCollection';
// import Create from '../componentsAxies/layouts/home-2/Create';

import api from "../services/api";

import Masonry from "react-masonry-css";

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';


import {Helmet} from "react-helmet";



 


const Profile: React.FC = () => {


  const [present, dismiss] = useIonLoading();

  const history = useHistory();

  const [product, setProduct] = useState<Product>();
  const [isProductOpen, setIsProductOpen] = useState(false);

  const [data, setData] = useState<Product[]>([]);

  const [productSearch, setProductSearch] = useState("");

  const [productSearchInput, setProductSearchInput] = useState("");

  const [onlyOnce, setOnlyOnce] = useState(true);

  const [choosedCategory, setChoosedCategory] = useState("");

  const [selected, setSelected] = useState("user");
  

  return (
    <IonPage className="home-7">
      {/* <CustomHeaderFade
        page={"Strona główna"}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
      /> */}


<Helmet>
        <title>Produkty</title>
        <meta name="description" content="" />
      </Helmet>


      <IonContent
        id="profile-page-controller"
        fullscreen={true}
        className={"background-lightgrey slide-transition-leave"}
        scrollEvents={false}
      >
        
        <HeaderStyle2 />

       
       <div className="container " style={{
         marginTop: "120px",
         marginBottom: "180px"
       }} >

<div className="row mt-5">
                <div className="col-3">

                  <IonRadioGroup
                    value={selected}
                    onIonChange={(e) => {

                      setSelected(e.detail.value)

                    }}
                    className="radio-primary"
                  >
                    
                    <IonItem
                      lines="none"
                      className={
                        selected == "user"
                          ? "mt-2 shadow-primary active"
                          : "mt-2 shadow-primary"
                      }
                      style={{
                        borderRadius: "14px",
                      }}
                    >
                      <div className="mb-2 mt-2">
                        <div className="d-flex align-items-center ">
                          <span style={{
                            fontWeight: 700,
                            fontSize: "20px"
                          }} className="ms-0 mb-1">Dane użytkownika</span>
                        </div>
                      </div>
                      <IonRadio slot="end" value="user" />
                    </IonItem>

                    <IonItem
                      lines="none"
                      className={
                        selected == "orders"
                          ? "mt-2 shadow-primary active"
                          : "mt-2 shadow-primary"
                      }
                      style={{
                        borderRadius: "14px",
                      }}
                    >
                      <div className="mb-2 mt-2">
                        <div className="d-flex align-items-center ">
                          <span style={{
                            fontWeight: 700,
                            fontSize: "20px"
                          }} className="ms-0 mb-1">Zamówienia</span>
                        </div>
                      </div>
                      <IonRadio slot="end" value="orders" />
                    </IonItem>

                    <IonItem
                      lines="none"
                      className={
                        selected == "contact"
                          ? "mt-2 shadow-primary active"
                          : "mt-2 shadow-primary"
                      }
                      style={{
                        borderRadius: "14px",
                      }}
                    >
                      <div className="mb-2 mt-2">
                        <div className="d-flex align-items-center ">
                          <span style={{
                            fontWeight: 700,
                            fontSize: "20px"
                          }} className="ms-0 mb-1">Kontakt</span>
                        </div>
                      </div>
                      <IonRadio slot="end" value="contact" />
                    </IonItem>
                    
                  </IonRadioGroup>
                </div>
                <div className="col-9">

                          <div className="shadow-primary p-4">
                            <h3>Dane podstawowe</h3>

                          <div className="mt-4 mb-4">
                          <div className="mt-2">
                              <strong>Status konta: </strong><strong style={{ color: "green" }}>Aktywne</strong>
                            </div>
                            <div className="mt-2">
                              <strong>Limit kredytowy: </strong>0,00 zł
                            </div>
                            <div className="mt-2">
                              <strong>Nazwa firmy: </strong>Mateusz Niemczyk transport
                            </div>
                            <div className="mt-2">
                            <strong>NIP: </strong>6252483975
                            </div>
                           <div className="mt-2">
                           <strong>REGON: </strong>523112441
                           </div>
                          </div>

                          <h3>Adres domyślny</h3>
                          <div className="mt-4 mb-4">
                            <div className="mt-2">
                              <strong>Kod pocztowy: </strong>42-504
                            </div>
                            <div className="mt-2">
                            <strong>Miasto: </strong>Będzin
                            </div>
                            <div className="mt-2">
                              <strong>Ulica i numer domu: </strong>Pokoju 29
                            </div>
                          </div>

                          <div className="mt-5">
                            <div style={{ textAlign: "center", color: "grey" }}>
                              W celu zmiany danych skontaktuj się z nami na adres mailowy info@zadbay.pl
                            </div>
                          </div>

                          </div>


                </div>
              </div>

       </div>

       <Footer />

      </IonContent>
    </IonPage>
  );
};

export default Profile;
