import {
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  isPlatform,
} from "@ionic/react";
import { Button, ButtonGroup, IconButton, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import CartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import "./ProductModal.css";
import { Product } from "../componentsAxies/TopSeller";
import { CartProps, useGlobalState } from "../GlobalStateProvider";
import { isNumber } from "lodash";

type ModalProps = {
  product?: Product;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductModal: React.FC<ModalProps> = ({ product, isOpen, setIsOpen }) => {

  const { setState, state } = useGlobalState();

  const [count, setCount] = useState(0);

  const ChangeCount = (_count: any, click: boolean = false) => {
    if (isNumber(_count)) {
      if (Number(_count) > 0 && Number(_count) < 100) {
        setCount(Number(_count));
        return;
      }
      else if(_count == 0 && click)
      {
        setCount(0);
        return;
      }
    }

    if (_count == "") {
      setCount(1);
    }
  };

  useEffect(() => {
    if (!product) {
      return;
    }

    if (state.cart) {
      let newCart: CartProps[] = [];
      let cart = state.cart;
      const cartIndex = state.cart.findIndex((e) => e.product.id == product.id);

      if (cartIndex >= 0) {
        if (cart[cartIndex].count != count) {

          if(count == 0)
          {
            for(const n of cart)
            {
              if(n.product.id != cart[cartIndex].product.id)
              {
                newCart.push(n);
              }
            }

            cart = newCart;
          }
          else
          {
            cart[cartIndex].count = count;
          }
          
          

          setState((prev) => ({
            ...prev,
            ...{
              cart: cart,
              isFabVisible: false,
            },
          }));
          setTimeout(() => {
            setState((prev) => ({
              ...prev,
              ...{
                isCartVisible: true,
              },
            }));
          }, 150);
        }
      }
    }
  }, [count]);

  return (
    <IonModal
      className="product-modal"
      style={{
        "--width": "1200px",
        "--height": "auto",
        "--max-height": "100vh",
        "--border-radius": "0px",
        "--overflow": "auto",
        paddingTop: "80px"
        // backdropFilter: "blur(2px)"
      }}
      isOpen={isOpen}
      mode="md"
      onDidDismiss={() => {
        setIsOpen(false);
      }}
    >
      <div className="row">
        <div className="col-6">
          <IonImg
            className="p-4 product-img"
            style={{
              margin: "auto",
              "--border-radius": "15px",
              width: "100%",
              height: "100%"
            }}
            src={product ? product.productImages.length > 0 ? product?.productImages[0].url : "" : ""}
          />
        </div>
        <div className="col-6" style={{ display: "flex" }}>
          <div className="p-1 py-5" style={{ margin: "auto" }}>
            <h2
              style={{
                // lineHeight: "48px",
                marginRight: "40px",
                textAlign: "center",
              }}
            >
              {product?.name}
            </h2>

            {/* <div
              className="row mt-3 mb-3"
              style={{
                borderTop: "1px solid rgb(223 223 223)",
                paddingTop: "20px",
              }}
            >
              {product?.features.map((e) => {
                return (
                  <div className="col-6 my-2">
                    <div className="row justify-content-between">
                      <div className="col-6 text-center-xy">{e.name}</div>
                      <div className="col-6 text-center-xy">
                        <strong>{e.value}</strong>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div> */}
            <div className="product-price">
              <div style={{ fontWeight: 800 }}>
                {product?.price
                  ? new Intl.NumberFormat("pl-PL", {
                      style: "currency",
                      currency: "PLN",
                    }).format(product.price)
                  : ""}
              </div>
              {/* <div className="product-price-description">
                Chcesz poznać cenę dla klienta dropshipping?
              </div> */}

              {!state.cart?.find((s) => s.product.id == product?.id) ? (
                <Button
                  style={{
                    margin: "15px 0 15px 0",
                    height: "56px",
                  }}
                  size="large"
                  variant="contained"
                  color="primary"
                  endIcon={<CartIcon />}
                  onClick={() => {
                    if (product) {

                      setCount(1);

                      let cart = state.cart;

                      if (!cart) {
                        cart = [];
                      }

                      cart.push({
                        product: {
                          id: product.id,
                          brand: product.brand,
                          productImages: product.productImages,
                          name: product.name,
                          price: product.price,
                          category: product.category,
                          description: product.description,
                          ean: product.ean,
                          // features: {},
                          stock: product.stock,
                          // vat: 0,
                        },
                        count: 1,
                      });

                      setState((prev) => ({
                        ...prev,
                        ...{
                          cart: cart,
                          isFabVisible: false,
                        },
                      }));
                      setTimeout(() => {
                        setState((prev) => ({
                          ...prev,
                          ...{
                            isCartVisible: true,
                          },
                        }));
                      }, 150);
                    }
                  }}
                >
                  Dodaj do koszyka
                </Button>
                              ) : (
                <ButtonGroup
                  sx={{
                    "& .MuiInputBase-formControl:after": {
                      opacity: "0 !important",
                    },
                    "& .MuiInputBase-formControl:before": {
                      opacity: "0 !important",
                    },
                    "& input": {
                      fontSize: "34px",
                      padding: "3px",
                      width: "100px",
                      textAlign: "center",
                    },
                  }}
                  style={{
                    margin: "15px 0 15px 0",
                  }}
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <IconButton
                    onClick={() => ChangeCount(count - 1, true)}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    onChange={(e) => ChangeCount(e.target.value)}
                    value={count}
                    variant="filled"
                  />
                  <IconButton
                    onClick={() => ChangeCount(count + 1)}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <AddIcon />
                  </IconButton>
                </ButtonGroup>
              )}

            </div>
          </div>

          <IconButton
            onClick={() => setIsOpen(false)}
            style={{ width: "50px", height: "50px", float: "right" }}
            color="primary"
            aria-label="close modal"
            component="span"
          >
            <CloseIcon style={{ fontSize: "37px" }} />
          </IconButton>
        </div>
      </div>
      <div className="row px-5 pt-4 pb-5">
        <div className="col-12">
          <h2>Opis produktu</h2>
          {product?.description ? (
            <div
              className="mt-3"
              dangerouslySetInnerHTML={{
                __html: product?.description,
              }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </IonModal>
  );
};

export default ProductModal;
