import { IonCol, IonGrid, IonImg, IonRow, isPlatform } from "@ionic/react";
import { Input, localePl, Popup, setOptions } from "@mobiscroll/react";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import img1 from "../assets/images/box-item/logo-01.png";
import img2 from "../assets/images/box-item/logo-01.png";
import img3 from "../assets/images/box-item/logo-01.png";

import logo from "../img/logo.png";

import { Link, useLocation } from "react-router-dom";
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from "swiper";

const SliderStyle2: React.FC = ({}) => {
  const subtitle = "NFT MARKETPLACE";
  const description = "Pomoc na start dla nowych sprzedających ecommerce!";
  const title =
    "Sprawdź specjalne oferty przystosowane do twojego biznesu";
  return (
    <section className="flat-title-page home5"  >
      <div className="overlay" style={{
      background: "url(https://products.zadbay.pl/background.jpg) center center no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "bottom"
    }}></div>
      <div className="themesflat-container">
        <div className="wrap-heading flat-slider d-flex align-items-center" style={{
          maxHeight: "100vh",
          padding: 0
        }} >
          <div className="content" style={{
            paddingLeft: "15px"
          }}>
            {/* <h4 className="mg-bt-11">
              <span className="fill">{subtitle}</span>
            </h4> */}
            <div>
                    <Link to="/" rel="home" >
                      <img
                        style={{ width: "50%" }}
                        id="logo_header"
                        src={logo}
                        alt="nft-gaming"
                      />
                    </Link>
                  </div>
            <h1 className="heading">Sprawdź specjalne<br/>oferty przystosowane<br/>do twojego biznesu</h1>
            <p className="sub-heading mg-t-7 mg-bt-39" style={{ textTransform: "initial", paddingRight: "0" }}>{description}</p>
            {/* <div className="flat-bt-slider style2 flex">
              <a
                href="/create-item"
                className="sc-button header-slider style style-1 rocket fl-button pri-1"
              >
                <span>DROPSHIPPING</span>
              </a>
              <a
                href="/explore-01"
                className="sc-button header-slider style style-1 note fl-button pri-1"
              >
                <span>OFERTA HURTOWA</span>
              </a>
            </div> */}
          </div>

          <Swiper
            modules={[Autoplay]}
            direction={"vertical"}
            spaceBetween={10}
            slidesPerView={5}
            loop
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={2000}
          >
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/1.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(20px)", padding: "30px" }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/3.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/4.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/6.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/5.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
          </Swiper>
          <Swiper
            modules={[Autoplay]}
            direction={"vertical"}
            spaceBetween={10}
            slidesPerView={5}
            loop
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={2100}
          >
            
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/6.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/1.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(20px)", padding: "30px" }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/3.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/4.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/5.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
          </Swiper>
          <Swiper
            modules={[Autoplay]}
            direction={"vertical"}
            spaceBetween={10}
            slidesPerView={5}
            loop
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={2200}
          >
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/4.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/6.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/5.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/1.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(20px)", padding: "30px" }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"https://products.zadbay.pl/marki/3.png"} alt="Axies" style={{ background: "rgba(244, 255, 255, 0.6)", backdropFilter: "blur(10px)", padding: "30px"  }} />
            </SwiperSlide>
            
          </Swiper>
          {/* <Swiper
            style={{
              height: "800px"
            }}
            modules={[Autoplay]}
            direction={"vertical"}
            spaceBetween={10}
            slidesPerView={5}
            loop
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={2000}
            className="end"
          >
            <SwiperSlide>
              <img src={img3} alt="Axies" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img2} alt="Axies" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img3} alt="Axies" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img1} alt="Axies" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img2} alt="Axies" />
            </SwiperSlide>
          </Swiper> */}
        </div>
      </div>
    </section>
  );
};

export default SliderStyle2;
