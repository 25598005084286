import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
} from "@ionic/react";
import {
  calendarOutline,
  desktopOutline,
  personCircle,
  personOutline,
} from "ionicons/icons";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
  CartProps,
} from "../GlobalStateProvider";

import { Storage } from "@ionic/storage";
import { Fab, Paper, Zoom, Button, IconButton } from "@mui/material";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import "./Cart.scss";
import { Product } from "../componentsAxies/TopSeller";
import ProductModal from "./ProductModal";
import { useHistory } from "react-router";

const Cart: React.FC = () => {

  const history = useHistory();

  // const storage = new Storage();
  const { setState, state } = useGlobalState();

  // useEffect(() => {
  //   setTimeout(() => {
  //     let cart: CartProps[] = [];

  //     for (let i = 0; i < 10; i++) {
  //       cart.push({
  //         id: i.toString(),
  //         company: "siema",
  //         image: "test",
  //         name: "nazwa produktu",
  //         price: 23.0 + i,
  //       });
  //     }

  //     setState((prev) => ({
  //       ...prev,
  //       ...{
  //         cart: cart,
  //       },
  //     }));
  //   }, 1000);
  // }, []);

  const [product, setProduct] = useState<Product>();
  const [isProductOpen, setIsProductOpen] = useState(false);

  return (
    <>

      <ProductModal product={product} isOpen={isProductOpen} setIsOpen={setIsProductOpen}/>

      <Zoom timeout={150} in={state.isFabVisible} unmountOnExit>
        <div
          style={{
            position: "absolute",
            bottom: "34px",
            right: "48px",
          }}
        >
          <Fab
            onClick={() => {

               setState((prev) => ({
                ...prev,
                ...{
                  isFabVisible: false
                },
              }));
              setTimeout(() => {
                setState((prev) => ({
                  ...prev,
                  ...{
                    isCartVisible: true
                  },
                }));
              }, 150);             
              
            }}
            style={{
              width: "75px",
              height: "75px",
            }}
            color="primary"
            aria-label="add"
          >
            <ShoppingCartIcon style={{ fontSize: "39px" }} />
          </Fab>

          {state.cart ? (
            state.cart.length > 0 ? (
              <Fab
                onClick={() => {

                  setState((prev) => ({
                    ...prev,
                    ...{
                      isFabVisible: false
                    },
                  }));
                  setTimeout(() => {
                    setState((prev) => ({
                      ...prev,
                      ...{
                        isCartVisible: true
                      },
                    }));
                  }, 150); 

                }}
                size="small"
                color="success"
                aria-label="add"
                className="cart-counter"
              >
                {state.cart.length}
              </Fab>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </Zoom>
      <Zoom timeout={150} in={state.isCartVisible} unmountOnExit>
        <Paper elevation={3} className="cart">
          <div style={{ margin: "15px 15px 0", position: "relative" }}>
            <Fab
              onClick={() => {
                setState((prev) => ({
                    ...prev,
                    ...{
                      isCartVisible: false
                    },
                  }));
                  setTimeout(() => {
                    setState((prev) => ({
                      ...prev,
                      ...{
                        isFabVisible: true
                      },
                    }));
                  }, 150); 
              }}
              style={{
                position: "absolute",
                top: "-23px",
                right: "-28px",
              }}
              color="primary"
              aria-label="add"
              size="small"
            >
              <CloseIcon />
            </Fab>

            <div style={{ paddingBottom: "10px" }}>
              <h3 style={{ marginBottom: "7px", textAlign: "center" }}>
                Koszyk
              </h3>

              <IonList style={{ maxHeight: "50vh", overflow: "auto" }}>
                {state.cart?.map((e) => {
                  return (
                    <IonItem
                      className="cart-item"
                      style={{
                        "--padding-start": 0,
                        "--padding-end": "10px",
                        opacity: 0,
                        animation:
                          "ease 0.6s 1 normal forwards running slide-up",
                      }}
                      lines="none"
                    >
                      <IconButton
                        color="error"
                        aria-label="remove cart"
                        component="span"
                        className="cart-remove"
                        onClick={() => {
                          const tempCart = state.cart;

                          if (tempCart) {
                            let newCart: CartProps[] = [];
                            for (const a of tempCart) {
                              if (a.product.id != e.product.id) {
                                newCart.push({ ...a });
                              }
                            }

                            setState((prev) => ({
                              ...prev,
                              ...{
                                cart: newCart,
                              },
                            }));
                          }
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                      <div>
                        <img className="cart-img" src={e.product.productImages.length > 0 ? e.product.productImages[0].url : ""} alt="product" />
                      </div>
                      <IonLabel style={{ margin: "auto 0" }}>
                        <IonItem className="desc-header" lines="none">
                          <IonLabel
                            style={{
                              textOverflow: "inherit",
                              whiteSpace: "initial",
                              lineHeight: "19px",
                            }}
                          >
                            {e.product.name}
                          </IonLabel>
                        </IonItem>
                        <IonItem lines="none">
                          <IonLabel>
                            <span>{e.product.brand}</span>
                            <span style={{ float: "right" }}>
                              {e.count} &times;{" "}
                              {new Intl.NumberFormat("pl-PL", {
                                style: "currency",
                                currency: "PLN",
                              }).format(e.product.price)}
                            </span>
                          </IonLabel>
                        </IonItem>
                      </IonLabel>
                    </IonItem>
                  );
                })}
              </IonList>
            </div>
            <hr style={{ margin: "0 0 10px 0" }} />
            <div>
              <div
                style={{ margin: "0", padding: "5px 20px" }}
                className="row justify-content-between"
              >
                <div className="col-auto">
                  <strong>Razem</strong>
                </div>
                <div className="col-auto">
                  {
                    new Intl.NumberFormat("pl-PL", {
                      style: "currency",
                      currency: "PLN",
                    }).format(state.cart ? state.cart.reduce(
                      (previousValue, currentValue) => previousValue + (currentValue.product.price * currentValue.count),
                      0) : 0)
                  }
                  </div>
              </div>
            </div>
            <hr style={{ margin: "10px 0 10px 0" }} />
            <div style={{ margin: "20px 0" }}>
              <Button fullWidth variant="contained" onClick={() => {

                history.push("/Payment");

                setState((prev) => ({
                  ...prev,
                  ...{
                    isCartVisible: false
                  },
                }));
                setTimeout(() => {
                  setState((prev) => ({
                    ...prev,
                    ...{
                      isFabVisible: true
                    },
                  }));
                }, 150); 

              }} >
                Przejdź do płatności
              </Button>
            </div>
          </div>
        </Paper>
      </Zoom>
    </>
  );
};

export default Cart;
