import {
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  isPlatform,
} from "@ionic/react";
import { Button, ButtonGroup, IconButton, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import CartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import "./ProductModal.css";
import { Product } from "../componentsAxies/TopSeller";
import { CartProps, useGlobalState } from "../GlobalStateProvider";
import { isNumber } from "lodash";
import { OrderUser } from "../pages/Payment";

type ModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisterModal: React.FC<ModalProps> = ({ isOpen, setIsOpen }) => {

  const { setState, state } = useGlobalState();

  const [user, setUser] = useState<OrderUser>({
    City: "",
    CompanyName: "",
    FirstName: "",
    HouseNumber: "",
    LastName: "",
    NIP: "",
    PostCode: "",
    Street: "",
    Email: "",
    Phone: "",
  });

  return (
    <IonModal
      className="product-modal"
      style={{
        "--width": "1200px",
        "--height": "auto",
        "--max-height": "100vh",
        "--border-radius": "0px",
        "--overflow": "auto",
        paddingTop: "80px"
        // backdropFilter: "blur(2px)"
      }}
      isOpen={isOpen}
      mode="md"
      onDidDismiss={() => {
        setIsOpen(false);
      }}
    >

      <div className="m-5">
      <div className="row">
        <div className="col-12">
          <h2>Formularz rejestracyjny</h2>
        </div>
      </div>
      <div className="row mt-4">
                    <div className="col-6">
                      <TextField
                        autoComplete="given-name"
                        label="Imię"
                        fullWidth
                        value={user.FirstName}
                        onChange={(e) =>
                          setUser({...user, FirstName: e.target.value})
                        }
                      />
                    </div>
                    <div className="col-6">
                      <TextField
                        autoComplete="family-name"
                        label="Nazwisko"
                        fullWidth
                        value={user.LastName}
                        onChange={(e) =>
                          setUser({...user, LastName: e.target.value})
                        }
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <TextField
                        autoComplete="organization"
                        label="Nazwa firmy"
                        fullWidth
                        value={user.CompanyName}
                        onChange={(e) =>
                          setUser({...user, CompanyName: e.target.value})
                        }
                      />
                    </div>
                    <div className="col-6">
                      <TextField
                        autoComplete="nip"
                        label="NIP"
                        fullWidth
                        value={user.NIP}
                        onChange={(e) =>
                          setUser({...user, NIP: e.target.value})
                        }
                      />
                    </div>
                  </div>

      </div>

    </IonModal>
  );
};

export default RegisterModal;
